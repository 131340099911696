window["configuration"] = Object.freeze({
  production: true,
  loginUrl: "login",
  chinaLocalKey: "US",
  apiDomainPrefix: "api-dev",
  s3BucketName: "boxxport-dev-public",
  STORAGE_PREFIX: "marketplaceDev_",
  isAdmin: false,
  displayMetaTag: true,
  USE_HASH: false,
  RE_CAPTCHA_KEY: "6Le7makUAAAAALRPMM71eQ-P2dD4ruSa8tUHR95y",
  // googleAnalyticsKey: "GTM-MPNBX3S",
  // googleAnalyticsKey: "GTM-N55KQQP",
  googleAnalyticsKey: "GTM-5W39XZL",
  GOOGLE_MAP_API_KEY: "AIzaSyBikqgaGD9YxmBZ8hg3niKKbQiNd8d0mqQ",
  SENDINBLUE_SUBSCRIPTION_IFRAME_KEY:
    "MUIEAMDPXqYBiEpBIofX4N9WkfBiVb4E_Kkj1clvZxqb4nkpWai7AJva6mfv5LuqgPY0VZ6t6DRbe2v1Mj8-LtGaS_cCrMvoUnsOWTXEwWnEXFeK0KM24NfDizn8lDwd2IsF2xlyew5-CP3Ed3j1ILFYrGfwapEGC7HyPR0-kMjhFm-KDASYoZSqG3UDOe5oPiM_25SOyI4xjZLo",
  STRIP_PUBLIC_KEY: "pk_test_QW9rrcsaLMufS48wYstJeLGH",
  PAYMENT_BETAS: "payment_intent_beta_3",
  isDev: true,
  ZendeskURL: "boxxport1560843936.zendesk.com",
  calendlyUrl: "https://calendly.com/boxxport-free-demo/sprint-test-demo",
  calendlyVideoVerificationUrl:
    "https://calendly.com/boxxport-free-demo/boxxport-verification-call",
  calendlyBuyBackDemoUrl:
    "https://calendly.com/boxxport-free-demo/boxxport-buyback-demo",
  PAYPAL_CLIENT_ID:
    "AYSPFKhTDGoCJshf9ZCsYp2uYCClieGjwf4MGLSIkFKVlpSXtN43C0vGobC24-eO9vuicxETE_sVZ2IE",
  PAYPAL_DISABLED_METHODS: "sepa,card,sofort,giropay",
  HELP_URL: "https://boxxport.zendesk.com/hc/en-us",
  PERSONIO_JOB_XML_URL: "https://boxxportdev.jobs.personio.de/xml?language=en",
  WORDPRESS_DATA_URL: "https://blog.boxxport.com/wp-json/wp/v2/",
  BRIDGER_PAY_CASHIER_KEY: "e9d66aed-6971-4ea5-88b9-c376e4b3b9b5",
  BRIDGER_PAY_API_URL: "https://checkout.bridgerpay.com/v2",
  BLUESNAP_SP_COLLECTOR_URL: "https://sandpay.bluesnap.com",
  PUSH_NOTIFICATION_PUBLIC_KEY:
    "BEVEr9msMQJRUTKQD2D0I-HGRqOvZ54IgU0RGyCZGJVuudsWjyNjbRClLzU2_Hy1fT97PJFhcItCUsX1z9I6QhU",
  ENCRYPTION_KEY: "XHYF0Sbo4oOQOktY",
  PARDOTCID: "",
});
